var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-3" },
      [
        _c("h3", [_vm._v("Draggable 1")]),
        _c(
          "draggable",
          {
            staticClass: "dragArea list-group",
            attrs: {
              list: _vm.list1,
              group: { name: "people", pull: "clone", put: false },
              clone: _vm.cloneDog,
            },
            on: { change: _vm.log },
          },
          _vm._l(_vm.list1, function (element) {
            return _c(
              "div",
              { key: element.id, staticClass: "list-group-item" },
              [_vm._v("\n        " + _vm._s(element.name) + "\n      ")]
            )
          }),
          0
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-3" },
      [
        _c("h3", [_vm._v("Draggable 2")]),
        _c(
          "draggable",
          {
            staticClass: "dragArea list-group",
            attrs: { list: _vm.list2, group: "people" },
            on: { change: _vm.log },
          },
          _vm._l(_vm.list2, function (element) {
            return _c(
              "div",
              { key: element.id, staticClass: "list-group-item" },
              [_vm._v("\n        " + _vm._s(element.name) + "\n      ")]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }